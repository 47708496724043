import { useState } from "react";

import { Container } from "@mui/material";
import { motion } from "framer-motion";

import HeadquartersLogo from "../Assets/IMG/HeadquartersLogo.svg";
import EmailBlueIcon from "../Assets/IMG/EmailBlueIcon.svg";
import WomanWoodenAirplane from "../Assets/IMG/WomanWoodenAirplane.svg";

import { Countries } from "../Assets/JSON/CountriesPhone";
import { message as MessageNotification } from "antd";
import { validateEmail } from "..";

const Contact = () => {
  const [formDetails, SetFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    country: Countries[0],
  });

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [isPhoneNumberAddonShowing, setPhoneNumberAddonShowing] =
    useState(false);

  const [isMessageSending, setMessageSending] = useState(false);

  const togglePhoneNumberAddonDisplay = () => {
    setPhoneNumberAddonShowing(!isPhoneNumberAddonShowing);
  };

  const sendMessage = () => {
    const { firstName, lastName, email, message } = formDetails;
    const isEmailValid = validateEmail(email);
    if (firstName === "") {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (lastName === "") {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (!isEmailValid) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (message === "") {
      setMessageError(true);
    } else {
      setMessageError(false);
    }

    if (!isEmailValid || firstName.length === 0 || lastName.length === 0) {
      MessageNotification.error("There are errors in your form!");
    } else {
      setMessageSending(true);
      setTimeout(() => {
        MessageNotification.success("Your message has been sent!");
        setMessageSending(false);
      }, 1500);
    }
  };
  return (
    <Container maxWidth="xl">
      <div className="contact-container">
        <br />
        <br />
        <span className="px-28 weight-600 text-black-secondary">
          Get In Touch
        </span>
        <br />
        <br />
        <div className="contact-form flex-column">
          <span className="px-16 weight-500 text-black-secondary">
            Send us a message
          </span>
          <div className="input-row flex-row">
            <input
              type="text"
              placeholder="First Name*"
              className={`default-input ${
                firstNameError ? "default-input-error" : ""
              }`}
              onChange={(e) => {
                SetFormDetails({ ...formDetails, firstName: e.target.value });
                if (e.target.value !== "") {
                  setFirstNameError(false);
                }
              }}
              spellCheck={false}
            />
            <input
              type="text"
              placeholder="Last Name*"
              className={`default-input ${
                lastNameError ? "default-input-error" : ""
              }`}
              onChange={(e) => {
                SetFormDetails({ ...formDetails, lastName: e.target.value });
                if (e.target.value !== "") {
                  setLastNameError(false);
                }
              }}
              spellCheck={false}
            />
          </div>
          <div className="input-row flex-row">
            <input
              type="text"
              placeholder="Email Address*"
              spellCheck={false}
              className={`default-input ${
                emailError ? "default-input-error" : ""
              }`}
              onChange={(e) => {
                SetFormDetails({ ...formDetails, email: e.target.value });
                if (validateEmail(e.target.value)) {
                  setEmailError(false);
                }
              }}
            />
            <div className="default-input-addon-parent flex-column">
              <div className="default-input-addon-container flex-row">
                <div
                  className="default-input-addon px-14 text-gray-secondary flex-row"
                  onClick={togglePhoneNumberAddonDisplay}
                >
                  {formDetails.country.iso} &nbsp;{" "}
                  <img src={formDetails.country.flag} alt="" />
                  <i className="far fa-chevron-down"></i>
                </div>
                <input
                  type="text"
                  placeholder="Phone Number*"
                  className="default-input-addon-text"
                  spellCheck={false}
                />
              </div>
              <motion.div
                className="phone-number-countries flex-column"
                initial={false}
                animate={{
                  display: isPhoneNumberAddonShowing ? "flex" : "none",
                }}
              >
                {Countries.map((country, index) => {
                  const { name, flag, iso } = country;
                  return (
                    <span
                      className={`phone-number-country flex-row ${
                        formDetails.country.name === name
                          ? "selected-phone-number-country"
                          : ""
                      }`}
                      onClick={() => {
                        SetFormDetails({ ...formDetails, country: country });
                        togglePhoneNumberAddonDisplay();
                      }}
                      key={index}
                    >
                      {iso} &nbsp; <img src={flag} alt="" /> {name}
                    </span>
                  );
                })}
              </motion.div>
            </div>
          </div>
          <textarea
            name=""
            placeholder="Message*"
            id=""
            cols="30"
            rows="10"
            className={`default-textarea ${
              messageError ? "default-input-error" : ""
            }`}
            onChange={(e) => {
              SetFormDetails({ ...formDetails, message: e.target.value });
              if (e.target.value !== "") {
                setMessageError(false);
              }
            }}
          ></textarea>
          <button
            className="btn-default px-16 text-white bg-green-secondary contact-us-btn"
            onClick={sendMessage}
            disabled={isMessageSending}
          >
            {isMessageSending ? (
              <>
                Sending <i className="fal fa-spinner fa-spin"></i>
              </>
            ) : (
              <>Send</>
            )}
          </button>
        </div>

        {/* <img src={GoogleMapsImage} alt="" className="google-maps-contact" /> */}

        <iframe
          title="Google Maps Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7910.781956206978!2d-12.522960323785952!3d7.532265164550071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0f615b4c75b5ef%3A0xb6427c32c751ff2f!2sSherbro%20International%20Airport%20(BTE)%2C%20Sierra%20Leone!5e0!3m2!1sen!2sng!4v1673299932278!5m2!1sen!2sng"
          className="google-maps-frame"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <br />
        <br />
        <div className="contact-information flex-column bg-white-secondary">
          <span className="px-17 weight-600 text-black-secondary">
            Contact Information
          </span>
          <div className="contact-information-bottom flex-row">
            <div className="contact-information-left flex-row">
              <div className="contact-information-item flex-row">
                <img src={HeadquartersLogo} alt="" />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <span className="px-15 text-black-secondary contact-info-text">
                  1st Floor, NDB Building <br /> 21/23 Siaka Stevens Street,{" "}
                  <br />
                  Freetown, Sierra Leone
                  <br />
                  <br />
                  <span className="weight-600">Phone:</span>: +232 212 463 63 63
                  <br />
                  <span className="weight-600">Fax: </span>
                  +232 212 465 21 21
                </span>
              </div>
              <div className="contact-information-item flex-column">
                <div className="flex-row" style={{ alignItems: "center" }}>
                  <img src={EmailBlueIcon} alt="" />
                  &nbsp;&nbsp;
                  <span
                    className="px-14 weight-500 text-black-secondary contact-info-text"
                    style={{ lineHeight: "15px" }}
                  >
                    General enquiries / information:
                    <br />
                    <span className="px-17 weight-600 text-black-secondary contact-info-text">
                      info@airsierraleone.com
                    </span>
                  </span>
                </div>
                <div className="flex-column" style={{ lineHeight: "30px" }}>
                  <span className="px-15 weight-500 text-black-secondary">
                    Bookings: gcare@airsierraleone.com
                  </span>
                  <span className="px-15 weight-500 text-black-secondary">
                    Media: media@airsierraleone.com
                  </span>
                  <span className="px-15 weight-500 text-black-secondary">
                    Careers: careers@airsierraleone.com
                  </span>
                </div>
              </div>
            </div>
            <img src={WomanWoodenAirplane} alt="" className="woman-airplane" />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </Container>
  );
};

export default Contact;
