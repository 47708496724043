export const recruitmentDatas = [
  {
    jobId: 1,
    position: "General Manager",
    ref: "ASL119",
    location: "Freetown, Sierra Leone ",
    dateCreated: "30th July, 2024",
    function: "Aviation",
    employmentType: "Full Time",
    reportingTo: "Chief Executive Officer ",
    workPlaceType: "On-site",
    jobSummary:
      "Air Sierra Leone, a startup airline in West Africa, is seeking a dynamic and experienced General Manager to lead our team. As the General Manager, you will be responsible for overseeing all aspects of the airline's operations, driving growth, and ensuring the highest level of customer satisfaction. This is a challenging and rewarding opportunity for a visionary leader with a passion for the aviation industry. ",
    coreResponsibilities: [
      "Develop and execute strategic plans to achieve the airline's goals and objectives.",
      "Oversee all operational activities, including flight operations, maintenance, ground services, and customer service.",
      "Manage and optimize the airline's resources, including aircraft, personnel, and budget.",
      "Ensure compliance with aviation regulations and safety standards.",
      "Foster a culture of excellence, teamwork, and customer-centricity within the organization.",
      "Lead and mentor a diverse team of professionals, providing guidance, support, and development opportunities.",
      "Collaborate with internal stakeholders and external partners to enhance the airline's market position and expand its network.",
      "Monitor market trends and competition to identify opportunities for growth and innovation.",
      "Develop and maintain strong relationships with key stakeholders, including government authorities, industry associations, and suppliers.",
      "Implement effective cost management strategies to optimize operational efficiency and profitability.",
      "Drive continuous improvement initiatives to enhance service quality and customer satisfaction.",
      "Represent the airline at industry events, conferences, and business meetings",
    ],
    requirements: [
      "Bachelor's Degree or equivalent ",
      "5 years management experience",
      "Previous Aviation/Airline experience highly preferred",
      "Maintain a relationship with both employees and clients",
      "Computer literacy in MS Office packages such as Word, Excel and Outlook.",
    ],
    additionalRequirements: [
      "Pass a background check",
      "Must be able to read, write and speak English. Bi-Lingual is a plus.",
      "Must have a good working knowledge of management practices and principles so they can efficiently perform his job.",
      "The job requires someone with critical thinking skills who can quickly observe a situation and make the appropriate response.",
    ],
    methodOfApplication: "General Manager",
    competencies: [
      "Leadership",
      "Teamwork Orientation",
      "Stress Management/Composure",
      "Ethical Conduct",
      "Good Communication",
      "Customer/Client Focus",
    ],
  },
  {
    jobId: 2,
    position: "Admin Executive",
    ref: "ASL120",
    location: "Freetown, Sierra Leone ",
    dateCreated: "30th July, 2024",
    employmentType: "Full Time",
    function: "Aviation",
    reportingTo: "General Manager",
    workPlaceType: "On-site",
    jobSummary:
      "Air Sierra Leone, a startup airline in West Africa, is seeking a dynamic and experienced Admin Executive to join our dynamic team in the airline industry. As an Admin Executive, you will play a crucial role in ensuring the smooth operations of our airline by providing administrative support to various departments. You will be responsible for managing office supplies, coordinating meetings and travel arrangements, and assisting with day-to-day administrative tasks.",
    coreResponsibilities: [
      "Provide administrative support to the airline's management team and various departments. ",
      "Manage office supplies, inventory, and equipment, ensuring availability and proper functionality. ",
      "Coordinate and schedule meetings, conferences, and travel arrangements for executives and employees.",
      "Handle incoming and outgoing correspondence, including emails, phone calls, and mail. ",
      "Prepare and distribute internal communications, memos, and reports.",
      "Maintain and update employee records, including attendance, leaves, and performance evaluations.",
      "Assist in the preparation of presentations, meeting agendas, and meeting minutes. ",
      "Coordinate with external vendors and service providers for facility maintenance and repairs.",
      "Support the HR department in recruitment and onboarding processes.",
      "Handle general administrative tasks such as data entry, filing, and record keeping.",
    ],
    requirements: [
      "Bachelor's degree in Business Administration or a related field.",
      "Proven experience as an Admin Executive or in a similar administrative role. ",
      "Proficient in MS Office, including Word, Excel, and PowerPoint. ",
      "Ability to maintain confidentiality and handle sensitive information.",
      "Ability to multitask and prioritize tasks effectively.",
    ],
    additionalRequirements: [
      "Strong attention to detail and accuracy. ",
      "Knowledge of the airline industry and its operations is a plus.",
      "Excellent organizational and time management skills.",
      "Excellent communication and interpersonal skills.",
    ],
    methodOfApplication: "Admin Executive",
    competencies: [
      "Leadership",
      "Teamwork Orientation",
      "Stress Management/Composure",
      "Ethical Conduct",
      "Good Communication",
      "Customer/Client Focus",
    ],
  },
  {
    jobId: 3,
    position: "HR Executive",
    ref: "ASL121",
    location: "Freetown, Sierra Leone ",
    dateCreated: "30th July, 2024",
    employmentType: "Full Time",
    function: "Aviation",
    reportingTo: "General Manager",
    workPlaceType: "On-site",
    jobSummary:
      "Air Sierra Leone, a startup airline in West Africa, is seeking a dynamic and experienced HR Executive to join our team in the airline industry. As an HR Executive, you will be responsible for managing all aspects of human resources operations and ensuring the smooth functioning of HR processes. You will play a crucial role in recruiting, onboarding, and retaining top talent, as well as implementing effective HR policies and practices.",
    coreResponsibilities: [
      "Develop and implement HR strategies and initiatives aligned with the airline's overall goals and objectives.",
      "Manage end-to-end recruitment processes, including job postings, screening, interviewing, and selection.",
      "Conduct orientation and onboarding programs for new hires, ensuring a smooth integration into the company.",
      "Administer and manage employee benefits programs, including health insurance, retirement plans, and employee assistance programs.",
      "Coordinate performance management processes, including goal setting, performance appraisals, and career development.",
      "Handle employee relations issues, including conflict resolution, disciplinary actions, and grievance procedures.",
      "Ensure compliance with employment laws and regulations, updating policies and procedures as necessary.",
      "Maintain accurate and up-to-date employee records, including personal information, attendance, and leave records.",
      "Coordinate training and development programs to enhance employee skills and knowledge.",
      "Manage employee engagement initiatives to foster a positive work environment and enhance employee satisfaction.",
      "Stay updated on industry trends and best practices in HR management.",
    ],
    requirements: [
      "Bachelor's degree in Human Resources, Business Administration, or a related field.",
      "Proven experience as an HR Executive or in a similar HR role, preferably in the airline industry.",
      "In-depth knowledge of HR principles, practices, and employment laws.",
      "Strong understanding of recruitment and talent management processes.",
      "Proficiency in HRIS software and MS Office suite.",
    ],
    additionalRequirements: [
      "Excellent communication and interpersonal skills.",
      "Ability to maintain confidentiality and handle sensitive employee information.",
      "Strong organizational and time management skills.",
      "Ability to work independently and as part of a team.",
      "Professional certification in HR management is a plus.",
    ],
    methodOfApplication: "HR Executive",
    competencies: [
      "Stress Management/Composure",
      "Ethical Conduct",
      "Good Communication",
    ],
  },
  {
    jobId: 4,
    position: "Finance Executive ",
    ref: "ASL122",
    location: "Freetown, Sierra Leone ",
    dateCreated: "30th July, 2024",
    employmentType: "Full Time",
    function: "Aviation",
    reportingTo: "General Manager",
    workPlaceType: "On-site",
    jobSummary:
      "Air Sierra Leone, a startup airline in West Africa, is seeking a dynamic and experienced Finance Executive to join our team. As a Finance Executive, you will be responsible for managing and overseeing all financial activities of the company. You will play a critical role in financial planning, budgeting, and forecasting, as well as analyzing financial data and providing strategic recommendations to drive business growth. ",
    coreResponsibilities: [
      "Manage and maintain financial records, including accounts payable and receivable, general ledger entries, and bank reconciliations.",
      "Prepare and analyze financial reports, budgets, and forecasts.",
      "Monitor cash flow and ensure proper allocation of funds.",
      "Oversee the preparation of financial statements and tax returns.",
      "Conduct financial analysis to identify trends, risks, and opportunities for improvement.",
      "Collaborate with other departments to ensure compliance with financial regulations and company policies.",
      "Provide strategic financial advice to senior management to support decision-making.",
      "Assist in the development and implementation of financial processes and procedures.Stay updated on industry trends and best practices in finance.",
    ],
    requirements: [
      "Bachelor's degree in Finance, Accounting, or related field.",
      "Proven experience as a Finance Executive or similar role.",
      "Strong knowledge of financial principles, regulations, and standards.",
      "Strong attention to detail and accuracy ",
    ],
    additionalRequirements: [
      "Ability to work independently and meet tight deadlines.",
      "Proficient in financial software and MS Office.",
      "Excellent analytical and problem-solving skills.",
      "Excellent communication and interpersonal skills.",
    ],
    methodOfApplication: "Finance Executive",
    competencies: [
      "Teamwork Orientation",
      "Stress Management/Composure",
      "Ethical Conduct",
      "Good Communication",
    ],
  },
];
