import { Box, Container, Grid, Typography } from "@mui/material";
import { recruitmentDatas } from "../Assets/JSON/JobDetails";
import { Link } from "react-router-dom";
import BlueLocationIcon from "../Assets/IMG/BlueLocationIcon.svg";
import WhiteAirplane from "../Assets/IMG/WhiteAirplane.svg";

export default function HomeRecruitPage() {
  const extraSmallFooterGrid = 12;
  const mediumFooterGrid = 6;

  return (
    <Container maxWidth="xl" style={{ height: "100%" }}>
      <div className="padding-block flex-column">
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            className="text-gray-tertiary weight-500"
            sx={{ fontSize: "35px" }}
          >
            Join Us!
          </Typography>
          <Typography className="text-gray-secondary weight-500 px-12">
            Start or continue your career with us and Experience the world with
            unmatched comfort and care
          </Typography>
        </Box>
        <center className="job-skills-center">
          <Grid container alignItems="flex-start" justifyContent={"flex-start"}>
            {recruitmentDatas.map((job) => {
              return (
                <Grid
                  xs={extraSmallFooterGrid}
                  md={mediumFooterGrid}
                  justifyContent="center"
                  alignItems="center"
                  key={job.jobId}
                  sx={{ padding: "10px", paddingBottom: "14px" }}
                >
                  <Link
                    to={`/job/${job.jobId}`}
                    className="recruitment-job home-recruitment-job bg-green-primary flex"
                  >
                    <div className="recruitment-job-left flex-column">
                      <Typography
                        className="text-gray-tertiary"
                        sx={{
                          fontSize: { sm: "15px", xs: "14px" },
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Position
                      </Typography>
                      <Typography
                        className="weight-800 text-blue-tertiary"
                        sx={{
                          fontSize: { sm: "20px", xs: "18px" },
                          textAlign: "left",
                        }}
                      >
                        {job.position}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          className="bg-green-tertiary recruitment-job-function flex-row"
                          sx={{
                            fontSize: { sm: "12px", xs: "10px" },
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          REF: {job.ref}
                        </Typography>
                      </Box>
                    </div>
                    <div className="recruitment-job-right flex-column">
                      <Typography
                        className="recruitment-job-location"
                        sx={{
                          fontSize: { sm: "16px", xs: "14px" },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={BlueLocationIcon} alt="" />
                        {/* {getFullJobLocation(job.location)} */}
                        {job.location}
                      </Typography>
                      <Typography
                        className="text-green-tertiary"
                        sx={{ fontSize: { sm: "14px", xs: "12px" } }}
                      >
                        Posted: {job.dateCreated}
                        {/* {getFullDate(job.date)} */}
                      </Typography>
                      <Box
                        className="bg-white-another recruitment-job-function flex-row"
                        sx={{ marginLeft: "auto" }}
                      >
                        {job.workPlaceType}
                      </Box>
                    </div>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </center>
        <Box display="flex" justifyContent="center" width={"100%"}>
          <Link
            to="/recruitment"
            className="bg-blue-default btn-default text-white px-13 btn-blue-default flex-row"
          >
            Join us today &nbsp;{" "}
            <img className="mega-search-nav-icon" src={WhiteAirplane} alt="" />
          </Link>
        </Box>
      </div>
    </Container>
  );
}
