import { useState } from "react";

import { Link } from "react-router-dom";

import { Box, Container } from "@mui/material";
import { motion } from "framer-motion";

import Logo from "../Assets/IMG/Logo.svg";
import EnglandFlag from "../Assets/IMG/Flags/EnglandFlag.svg";
import FrenchFlag from "../Assets/IMG/Flags/FrenchFlag.svg";
// import ChineseFlag from "../Assets/IMG/Flags/ChineseFlag.svg";
// import ItalianFlag from "../Assets/IMG/Flags/ItalianFlag.svg";
// import JapaneseFlag from "../Assets/IMG/Flags/JapaneseFlag.svg";
// import PortugalFlag from "../Assets/IMG/Flags/PortugalFlag.svg";
// import SpanishFlag from "../Assets/IMG/Flags/SpanishFlag.svg";

const Navbar = () => {
  const LANGUAGES = [
    { short: "ENG", name: "English", flag: EnglandFlag },
    { short: "FRN", name: "French", flag: FrenchFlag },
    // { short: "SPN", name: "Spanish", flag: SpanishFlag },
    // { short: "POR", name: "Portuguese", flag: PortugalFlag },
    // { short: "ITA", name: "Italian", flag: ItalianFlag },
    // { short: "CHN", name: "Chinese", flag: ChineseFlag },
    // { short: "JPN", name: "Japanese", flag: JapaneseFlag },
  ];

  const [isSmallMenuOpen, setSmallMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES[0]);

  return (
    <>
      <div className="mobile-nav">
        <span className="mobile-nav-logo">
          <Link to="/">
            <img src={Logo} alt="" className="small-navbar-logo" />
          </Link>
        </span>
        <span
          className="toggle-nav-menu flex-row"
          onClick={() => {
            setSmallMenuOpen(!isSmallMenuOpen);
          }}
        >
          {isSmallMenuOpen ? "" : <i className="far fa-bars"></i>}
        </span>
      </div>
      <div className="navbar-container">
        <Container maxWidth="xl">
          <div className="navbar flex-row">
            <Link to="/">
              <img src={Logo} alt="" className="navbar-logo" />
            </Link>
            <div className="navbar-items flex-row">
              <Link className="navbar-item" to="/recruitment">
                Recruitment
              </Link>

              <Link className="navbar-item" to="/contact">
                Contact Us
              </Link>
              {/* <Link
                className="navbar-item navbar-item-language flex-row"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  setLanguageMenuOpen(!isLanguageMenuOpen);
                }}
              >
                <img src={currentLanguage.flag} alt="" />
                {currentLanguage.short}
              </Link> */}
            </div>
          </div>
          {isSmallMenuOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "0",
                height: "100vh",
                width: "100vw",
                background: "#0000004d",
              }}
              onClick={() => setSmallMenuOpen(!isSmallMenuOpen)}
            />
          )}
          <Box
            className="sidebar"
            sx={{
              marginLeft: isSmallMenuOpen ? "" : "-1000px",
            }}
          >
            <Box
              sx={{
                padding: "29px 20px 19px",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" className="small-navbar-logo" />
              </Link>
            </Box>
            <Box
              sx={{
                borderBottom: "3px solid #058E6F",
                width: "100%",
                marginTop: "10px",
              }}
            />
            {/* <motion.div
              initial={false}
              animate={{
                height: isSmallMenuOpen ? "fit-content" : 0,
                visibility: isSmallMenuOpen ? "visible" : "hidden",
                overflow: "clip",
                // overflow: isSmallMenuOpen ? "auto" : "clip",
              }}
              className="small-navbar-items flex-column"
            >
              */}
            <Link
              className="small-navbar-item"
              to="/recruitment"
              onClick={() => setSmallMenuOpen(!isSmallMenuOpen)}
            >
              Recruitment
            </Link>
            <Link
              className="small-navbar-item"
              to="/contact"
              onClick={() => setSmallMenuOpen(!isSmallMenuOpen)}
            >
              Contact Us
            </Link>
            {/* <Link
              className="small-navbar-item small-navbar-item-language flex-row"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                setLanguageMenuOpen(!isLanguageMenuOpen);
              }}
            >
              <img src={currentLanguage.flag} alt="" />
              {currentLanguage.short}
            </Link> */}
            <Box
              sx={{
                position: "absolute",
                top: "45%",
                right: 0,
                marginRight: "-60px",
                background: "#058E6F",
                borderRadius: "50%",
                padding: "20px",
                color: "white",
              }}
              role="button"
              onClick={() => {
                setSmallMenuOpen(!isSmallMenuOpen);
              }}
            >
              <i class="fas fa-arrow-left"></i>
            </Box>
            {/* </motion.div> */}
          </Box>
          <div className="language-dropdown-container flex-row">
            <motion.div
              initial={false}
              animate={{
                height: isLanguageMenuOpen ? "150px" : 0,
                display: isLanguageMenuOpen ? "flex" : "none",
              }}
              className="language-dropdown flex-column"
            >
              {LANGUAGES.map((language, index) => {
                return (
                  <span
                    className="language-dropdown-item flex-row"
                    onClick={() => {
                      setCurrentLanguage(language);
                      setLanguageMenuOpen(false);
                    }}
                    key={index}
                  >
                    <img src={language.flag} alt="" />
                    <span className="language-dropdown-short">
                      {language.short}
                    </span>
                  </span>
                );
              })}
            </motion.div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Navbar;
