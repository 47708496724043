import NigerianFlag from "../IMG/NigerianBanner.svg";
const Countries = [
  {
    name: "NIG",
    flag: NigerianFlag,
    iso: "+234",
  },
];

export { Countries };
